export const GoogleGlid = "gclid";
export const FacebookFbclid = "fbclid";
export const GoogleSrc = "gclsrc";
export const ReadPeakClickId = "rpcid";
export const SnapChatId = "ScCid";
export const TikTokId = "ttclid";

export function removeClidId(clidType: string) {
  if (typeof window !== "undefined") {
    try {
      const storedGclid = JSON.parse(sessionStorage.getItem(clidType) || "{}");
      if (!storedGclid?.value) {
        return;
      }
      const isGclidValid =
        storedGclid && new Date().getTime() < storedGclid.expiryDate;

      if (!isGclidValid) {
        localStorage.removeItem(clidType);
      }
    } catch {
      localStorage.removeItem(clidType);
    }
  }
}

export function addTrackingFromStorageToUrl(url: string) {
  if (typeof window !== "undefined") {
    try {
      const gclid = localStorage.getItem(GoogleGlid);
      const fbclid = localStorage.getItem(FacebookFbclid);
      const readPeak = localStorage.getItem(ReadPeakClickId);
      const snapChatId = localStorage.getItem(SnapChatId);
      const tikTokId = localStorage.getItem(TikTokId);

      const urlObject = new URL(url);
      if (gclid) {
        const parsedGclid = JSON.parse(gclid);
        if (parsedGclid.expiryDate < new Date().getTime()) {
          localStorage.removeItem(GoogleGlid);
          return url;
        }
        urlObject.searchParams.set(GoogleGlid, parsedGclid.value);
        urlObject.searchParams.set("expiryDate", parsedGclid.expiryDate);
      }

      if (fbclid) {
        const parsedFbclid = JSON.parse(fbclid);
        if (parsedFbclid.expiryDate < new Date().getTime()) {
          localStorage.removeItem(FacebookFbclid);
          return url;
        }
        urlObject.searchParams.set(FacebookFbclid, parsedFbclid?.value);
      }

      if (readPeak) {
        const parsedRPId = JSON.parse(readPeak);
        if (parsedRPId.expiryDate < new Date().getTime()) {
          localStorage.removeItem(ReadPeakClickId);
          return url;
        }
        urlObject.searchParams.set(ReadPeakClickId, parsedRPId.value);
      }

      if (snapChatId) {
        const parsedSC = JSON.parse(snapChatId);
        if (parsedSC.expiryDate < new Date().getTime()) {
          localStorage.removeItem(SnapChatId);
          return url;
        }
        urlObject.searchParams.set(SnapChatId, parsedSC.value);
      }

      if (tikTokId) {
        const parsedTikTok = JSON.parse(tikTokId);
        if (parsedTikTok.expiryDate < new Date().getTime()) {
          localStorage.removeItem(TikTokId);
          return url;
        }
        urlObject.searchParams.set(TikTokId, parsedTikTok.value);
      }

      return urlObject.toString();
    } catch (e) {
      //
    }
  }
  return url;
}

export const getParam = (p: string, url: string): string | null => {
  const match = RegExp(`[?&]${p}=([^&]*)`).exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

export const getExpiryRecord = (value: string, expiredTime?: number) => {
  const expiryPeriod = expiredTime ?? 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
  const expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value,
    expiryDate,
  };
};
