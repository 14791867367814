/* eslint-disable import/prefer-default-export */

// Font list
export const InterFontList = [
  '"Inter"',
  '"Roboto"',
  '"Helvetica"',
  '"Arial"',
  "sans-serif",
].join(",");

export const ErlikFontList = [
  '"Erlik"',
  '"sans-serif"',
  '"Helvetica"',
  '"Roboto"',
  '"Arial"',

].join(",");

export const BiroFontList = [
  '"Biro"',
  '"sans-serif"',
  '"Roboto"',
  '"Helvetica"',
  '"Arial"',

].join(",");